div {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
ul {
  background: #eee;
  margin: 0px;
  padding: 0px;
  list-style: none;
  border: 1px solid #bbb;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
}
